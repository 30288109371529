import clsx from 'clsx';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { getConfig } from '@customer-frontend/config';
import {
  Maybe,
  ProblemType,
  QuizApplicationResponse,
  QuizQuestion,
  QuizQuestionType,
  useTrackApplicationStopMutation,
} from '@customer-frontend/graphql-types';
import { NextParams, useQuizState } from '@customer-frontend/quiz';
import { useUpdateProfileMutation } from '@customer-frontend/services';
import {
  Button,
  ButtonProps,
  LoadingSpinner,
  Markdown,
  Typography,
  TypographySize,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  formatLocaleDateToISO,
  formatISODateToLocale,
} from '@customer-frontend/utils';

import {
  calculateNextQuestionId,
  calculatePreviousQuestionId,
  getPrimaryButtonPalette,
} from './helpers';
import { ControlledImageUpload } from './inputs/image-upload-input';
import { QuestionInput } from './inputs/question-input';
import { QuestionPopup } from './question-popup';
import { ReviewQuestion } from './review-question/review-question';
import { InitialAttributes } from './review-question/initial-attributes';
import { QuizExperiments } from './experiments/experiments';
import { useQuizExperimentCohort } from './experiments/use-quiz-experiment-cohort';

export interface QuestionCopyProps {
  title: Maybe<string>;
  description: Maybe<string>;
  titleSize?: TypographySize;
}

export type QuestionNextButtonLabel =
  | ((questionType: QuizQuestionType) => string)
  | string;

const BASE_TRANSITION_TIME_IN_SECONDS = 1;
const TRANSITION_WORDS_PER_SECOND = 5.5;

function getAnswer(
  question?: QuizQuestion,
  answer?: QuizApplicationResponse,
): string | string[] | undefined {
  if (!question) {
    return;
  }
  switch (question.type) {
    case 'DATE':
      return answer?.answer ? formatISODateToLocale(answer.answer) : undefined;
    case 'CHECKBOX':
      return answer?.answersArray;
    case 'DROPDOWN':
      return answer?.answer ?? question.options[0];
    default:
      return answer?.answer || '';
  }
}

function getDefaultQuestionValue(question?: QuizQuestion): string | undefined {
  const { dateConfig } = getConfig();
  // set default value of non dob date fields to current date
  if (question?.type === 'DATE' && question.shortcode !== 'dob') {
    return format(new Date(), dateConfig.format);
  }
}

function getNextParams(
  question: QuizQuestion,
  response: QuizApplicationResponse | undefined,
  answer: string[] | string | undefined,
): NextParams {
  return {
    answerId: response?.id,
    answer: Array.isArray(answer) ? undefined : answer,
    answersArray: Array.isArray(answer) ? answer : undefined,
    question: {
      id: question.id,
      type: question.type,
    },
  };
}

export function Question({
  NextButtonComponent = Button,
  reviewImageInstructions,
  isForwards,
  nextButtonLabel,
  setForwards = (): void => {
    /* noop */
  },
  onRestart,
  problemType,
  goToQuestion,
  brandColors,
}: {
  NextButtonComponent?: React.FC<ButtonProps>;
  reviewImageInstructions?: Partial<Record<ProblemType, string[]>>;
  nextButtonLabel?: QuestionNextButtonLabel;
  isForwards?: boolean;
  setForwards?: (isForwards: boolean) => void;
  onRestart?: () => void;
  problemType?: ProblemType;
  goToQuestion: (questionId: string) => void;
  brandColors?: { cardBgColor: string };
}): React.ReactElement {
  const state = useQuizState();
  const { formatMessage } = useIntl();
  const formMethods = useForm();
  const config = getConfig();
  const notify = useNotification();
  const nodeRef = useRef<HTMLFormElement>(null);

  const { watch, handleSubmit, setValue, control } = formMethods;
  // Precedence of answer is local state => prefill => empty
  const savedAnswer =
    (getAnswer(state.question, state.answer) || state.prefill) ?? '';

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [updateProfileMutation] = useUpdateProfileMutation();
  const [trackApplicationStop] = useTrackApplicationStopMutation();

  const experimentCohort = useQuizExperimentCohort(state.question?.shortcode);

  const nextLabel = !nextButtonLabel
    ? formatMessage({
        defaultMessage: 'Next',
      })
    : nextButtonLabel;

  const cssTransitionProps = {
    activeQuestionIndex: state.activeQuestionIndex,
    isForwards,
    onEntered: () => {
      if (isForwards) {
        setForwards(false);
      }
    },
    nodeRef,
  };

  React.useEffect(() => {
    if (!isSubmitting) {
      if (savedAnswer) {
        setValue(state?.question?.id ?? '', savedAnswer);
      } else {
        setValue(
          state?.question?.id ?? '',
          getDefaultQuestionValue(state.question),
        );
      }
    }
  }, [
    savedAnswer,
    setValue,
    state?.question?.id,
    state?.question,
    isSubmitting,
  ]);

  const handleFormSubmit = React.useMemo(
    () =>
      handleSubmit(async (data) => {
        setIsSubmitting(true);
        setForwards(true);
        try {
          const answer = data?.[state?.question?.id ?? ''];

          let submittedAnswer = answer;

          if (
            state.question?.type === 'IMAGEUPLOAD' &&
            state.question.required === true &&
            !answer
          ) {
            notify.error({
              message: formatMessage({
                defaultMessage: 'Please take or upload a photo to proceed',
                description:
                  'error message when customer submits a photo question without a required photo',
              }),
            });
            return;
          }

          if (state.question?.type === 'DATE' && !Array.isArray(answer)) {
            submittedAnswer = formatLocaleDateToISO(answer);
          }

          if (state?.question?.shortcode === 'phone') {
            try {
              await updateProfileMutation({
                variables: {
                  phone: answer,
                },
              });
            } catch (err) {
              return;
            }
          }

          if (state.question) {
            await state.next(
              getNextParams(state.question, state.answer, submittedAnswer),
            );
          }
        } finally {
          setIsSubmitting(false);
        }
      }),
    [
      handleSubmit,
      setForwards,
      state,
      updateProfileMutation,
      formatMessage,
      notify,
    ],
  );

  const transitionTimeoutId = useRef<number>();

  useEffect(() => {
    if (state.question?.type === 'TRANSITION' && !transitionTimeoutId.current) {
      const numOfWords =
        state.question?.description?.match(/\S+/g)?.length ?? 0;
      const time = numOfWords ? numOfWords / TRANSITION_WORDS_PER_SECOND : 0;
      transitionTimeoutId.current = window.setTimeout(
        handleFormSubmit,
        (time + BASE_TRANSITION_TIME_IN_SECONDS) * 1000,
      );

      return (): void => {
        window.clearTimeout(transitionTimeoutId.current);
        transitionTimeoutId.current = undefined;
      };
    }
  }, [state.question?.description, handleFormSubmit, state.question?.type]);

  useEffect(() => {
    if (state.question?.type === 'STOP' && state.quizApplication?.id) {
      trackApplicationStop({
        variables: {
          id: state.quizApplication.id,
        },
      });
    }
  }, [state.question?.type, state.quizApplication?.id, trackApplicationStop]);

  if (!state.question) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  // Handle skipping over the experiment question if the user is in a control or not enrolled group
  if (
    state.quizApplication?.quiz &&
    experimentCohort &&
    !experimentCohort.includes('variation')
  ) {
    if (isForwards) {
      const nextQuestionId = calculateNextQuestionId(
        state.quizApplication.quiz,
        undefined,
        state.question.id,
      );

      if (nextQuestionId) {
        goToQuestion(nextQuestionId);
      }
    } else {
      const previousQuestionId = calculatePreviousQuestionId(
        state.quizApplication.quiz,
        undefined,
        state.question.id,
      );

      if (previousQuestionId) {
        goToQuestion(previousQuestionId);
      }
    }
  }

  if (state.question.type === 'STOP') {
    return (
      <Copy
        title={state.question.title}
        description={state.question?.description}
      />
    );
  }

  // Image upload component contains its own copy/submit button
  if (state.question.type === 'IMAGEUPLOAD') {
    const { title, description, imgUrl } = state.question;
    const questionId = state.question?.id;
    return (
      <form onSubmit={handleFormSubmit}>
        {state.question && (
          <ControlledImageUpload
            loading={isSubmitting}
            key={questionId}
            name={questionId}
            control={control}
            questionId={questionId}
            title={title ?? ''}
            description={description ?? ''}
            imgUrl={imgUrl ?? ''}
            reviewImageInstructions={
              reviewImageInstructions && state.quizApplication
                ? reviewImageInstructions[state.quizApplication?.problemType]
                : undefined
            }
          />
        )}
      </form>
    );
  }

  const hasNextButton = ![
    'RADIO',
    'RADIO_IMG',
    'TRANSITION',
    'REVIEW',
  ].includes(state.question.type ?? '');

  // Disable button if question is required, but not answered
  const buttonDisabled =
    !!state?.question?.required &&
    !watch()[state.question.id] &&
    state.question.type !== 'CONTINUE';

  let questionCopyProps: QuestionCopyProps = {
    title: state.question?.title,
    description: state.question?.description,
  };
  if (state.question?.type === 'TRANSITION') {
    questionCopyProps = {
      title: state.question?.description,
      description: undefined,
      titleSize: 'lg',
    };
  }

  if (state.question.type === 'REVIEW') {
    const isWeightInitial =
      state.quizApplication?.problemType === 'WEIGHT_LOSS' &&
      state.quizApplication.purpose === 'INITIAL';
    return (
      <QuestionTransition {...cssTransitionProps}>
        <form onSubmit={handleFormSubmit}>
          <ReviewQuestion
            questionCopyProps={questionCopyProps}
            isSubmitting={isSubmitting}
            attributesCard={
              isWeightInitial ? (
                <InitialAttributes
                  responses={state.quizApplication?.responses}
                />
              ) : null
            }
            onRestart={onRestart}
            brandColors={brandColors}
          />
        </form>
      </QuestionTransition>
    );
  }

  return (
    <QuestionTransition {...cssTransitionProps}>
      <form
        ref={nodeRef}
        onSubmit={handleFormSubmit}
        onClick={() => {
          if (state.question?.type === 'TRANSITION') {
            handleFormSubmit();
          }
        }}
      >
        {experimentCohort && experimentCohort.includes('variation') ? (
          <FormProvider {...formMethods}>
            <QuizExperiments
              question={state.question}
              isLoading={isSubmitting}
              experimentCohort={experimentCohort}
            />
          </FormProvider>
        ) : (
          <>
            <div className="flex flex-col items-center mb-6 text-center">
              {state.question.imgUrl && (
                <img
                  className={clsx(
                    'pb-4',
                    state.question?.type === 'TRANSITION' &&
                      'h-auto max-h-40 md:max-h-80 md:pb-6',
                  )}
                  src={state.question.imgUrl}
                  alt={formatMessage({
                    defaultMessage: 'more information about the product',
                  })}
                />
              )}
              <Copy {...questionCopyProps} />
            </div>
            {state.question.popup && (
              <div className="mt-4 self-start">
                <QuestionPopup
                  {...state.question.popup}
                  linkOutText={
                    state.question.popup.linkOutText ||
                    formatMessage({
                      defaultMessage: 'Why are we asking this?',
                    })
                  }
                />
              </div>
            )}
            <div className="mb-5 sm:mb-8">
              <FormProvider {...formMethods}>
                <QuestionInput
                  key={state.question.id}
                  question={state.question}
                  isLoading={isSubmitting}
                  problemType={problemType}
                />
              </FormProvider>
            </div>
            {hasNextButton && (
              <NextButtonComponent
                level="primary"
                palette={getPrimaryButtonPalette(config.brand)}
                isDisabled={buttonDisabled}
                isLoading={isSubmitting}
                isSubmit
                isFullWidth
              >
                {typeof nextLabel === 'string'
                  ? nextLabel
                  : nextLabel(state.question.type)}
              </NextButtonComponent>
            )}
          </>
        )}
      </form>
    </QuestionTransition>
  );
}

export const Copy = ({
  title,
  description,
  titleSize,
}: QuestionCopyProps): React.ReactElement => {
  const defaultTitleSize = title && title.length > 20 ? 'lg' : 'xl';

  return (
    <>
      {title && (
        <Typography size={titleSize || defaultTitleSize} element="h1" isBold>
          {title}
        </Typography>
      )}
      {description && (
        <div className="mt-4 space-y-4">
          <Markdown src={description} inheritColor={false} />
        </div>
      )}
    </>
  );
};

const QuestionTransition = ({
  children,
  activeQuestionIndex,
  isForwards,
  onEntered,
  nodeRef,
}: {
  children: React.ReactElement;
  activeQuestionIndex: number | undefined;
  isForwards: boolean | undefined;
  onEntered: () => void;
  nodeRef: React.RefObject<HTMLFormElement>;
}): React.ReactElement => {
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={activeQuestionIndex}
        addEndListener={(done: () => void): void =>
          nodeRef.current?.addEventListener('transitionend', done, false)
        }
        classNames={isForwards ? 'fade' : 'fade-backwards'}
        nodeRef={nodeRef}
        onEntered={onEntered}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};
