import { gql, useApolloClient, useQuery } from '@apollo/client';
import {
  InitialPurchaseConfirmInitiateZipCheckoutMutation,
  InitialPurchaseConfirmInitiateZipCheckoutMutationVariables,
  InitialPurchaseConfirmUpdateResidentialAddressMutation,
  InitialPurchaseConfirmUpdateResidentialAddressMutationVariables,
  InitialPurchaseConfirmUpdateShippingMutation,
  InitialPurchaseConfirmUpdateShippingMutationVariables,
  InitialPurchaseConfirmUpdateProfileMutation,
  InitialPurchaseConfirmUpdateProfileMutationVariables,
  InitialPurchaseConfirmQuery,
  InitialPurchaseConfirmQueryVariables,
  OfferingSelectionInput,
  SequenceSelectionInput,
  InitialPurchaseConfirmShippingAddressWithinRangeQuery,
  InitialPurchaseConfirmShippingAddressWithinRangeQueryVariables,
} from '@customer-frontend/graphql-types';
import { ReactComponent as CircleTickOutline } from '../assets/circle-tick-outline.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Warning } from '../assets/warning-triangle.svg';
import {
  AccordionList,
  AccordionPalette,
  Button,
  ButtonPalette,
  CardPalette,
  Checkbox,
  Divider,
  LoadingSpinner,
  Markdown,
  TextInput,
  Typography,
  TypographySize,
  useNotification,
  useResponsive,
} from '@eucalyptusvc/design-system';
import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import {
  defineMessage,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import { motion } from 'framer-motion';
import { Redirect, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { useForm, SubmitHandler } from 'react-hook-form';
import { formatCurrency } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import {
  formatISODateToLocale,
  formatLocaleDateToISO,
  matchesValue,
  mustBeTrue,
  useMinLengthValidation,
  useRequiredValidation,
  unicodeToBase64,
  useTitle,
  getZendeskRequestUrl,
  getFullAddressString,
  scrollToRef,
} from '@customer-frontend/utils';
import {
  clearDiscountParams,
  usePersistedDiscountFromURL,
} from '@customer-frontend/order';
import { WeightLossGraphSVG } from '@customer-frontend/quiz';
import {
  useElements,
  useStripe,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js';
import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import clsx from 'clsx';
import { useEnvironment } from '@customer-frontend/environment';
import {
  IntlAddressInput,
  IntlDOBInput,
  IntlMobileNumberInput,
  AddressFields as AddressFieldsIntl,
} from '@customer-frontend/intl';
import { uiStorages } from '@customer-frontend/ui-storage';
import {
  FormPaymentFields,
  PaymentMethods,
  PurchaseOfferingsIntentHandlerInput,
  usePreselectedOfferingSelection,
} from '@customer-frontend/page-templates';
import {
  StripeProvider,
  useBackButtonBehaviour,
} from '@customer-frontend/services';
import { Logger } from '@customer-frontend/logger';
import {
  useFeatureFlagBoolean,
  useFeatureFlagClient,
} from '@customer-frontend/feature-flags';
import { appBlurbMessages } from './app-blurb';
import {
  StripeError,
  StripeExpressCheckoutElementReadyEvent,
  StripeExpressCheckoutElementClickEvent,
} from '@stripe/stripe-js';
import {
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventServiceDirectContext,
} from '@customer-frontend/events';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { ExperimentConsultPayLoadingTimeInner } from './xp-initial-purchase-confirm';

type AddressFields = {
  line1?: string;
  line2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  prefecture?: string;
  municipality?: string;
  townArea?: string;
  country?: string;
};

type PersonalDetailsFormFields = {
  email?: string;
  password?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  confirmPassword?: string;
};

type ResidentialAddressFormFields = {
  residentialAddress?: AddressFields;
};

type ShippingAddressFormFields = {
  shippingAddress?: AddressFields;
};

type PaymentFormFields = {
  couponCode?: string;
  payment: FormPaymentFields;
  agreedToTerms: boolean;
};

const scrollOffset = 150;

const checkmarkAnimationTransition = {
  type: 'spring',
  stiffness: 200,
  ease: 'easeIn',
  x: {
    duration: 0.6,
  },
  y: {
    duration: 0.6,
  },
};

type CheckableCardProps = {
  title: MessageDescriptor;
  index: number;
  isActive: boolean;
  isComplete: boolean;
  colors: InitialPurchaseConfirmProps['brandColors'];

  children: React.ReactNode;
  footer?: React.ReactNode;
  onEdit?: (cardIndex: number) => void;
  onCheckAnimationComplete?: () => void;
};

const CheckableCardWithRef: ForwardRefRenderFunction<
  HTMLDivElement,
  CheckableCardProps
> = (props, ref) => {
  const {
    title,
    index,
    isActive,
    isComplete,
    children,
    colors: {
      completedCheckColorClassName,
      completedCheckBgColorClassName,
      borderInactiveColorClassName,
      borderActiveColorClassName,
      separatorColorClassName,
      completedCheckBorderColorClassName,
      activeCheckedBgColorClassName,
      activeCheckedBorderClassName,
    },
    footer,
    onEdit,
    onCheckAnimationComplete,
  } = props;

  const onEditCurrentCard: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onEdit) {
      onEdit(index);

      if (ref && 'current' in ref) {
        scrollToRef(ref, scrollOffset);
      }
    }
  };

  return (
    <div
      ref={ref}
      className={clsx(
        'relative rounded-lg overflow-hidden bg-white',
        borderInactiveColorClassName && borderActiveColorClassName && 'border',
        !isActive && borderInactiveColorClassName,
        isActive && borderActiveColorClassName,
        { 'pointer-events-none': !isActive && !isComplete },
      )}
    >
      <div
        className={clsx('flex flex-col text-primary-600', {
          'opacity-20': !isActive && !isComplete && index !== 1,
        })}
      >
        <div
          className={clsx(
            'p-4 flex items-center border-b',
            separatorColorClassName,
          )}
        >
          <div
            className={clsx(
              'rounded-full w-8 h-8 mr-4 flex items-center justify-center flex-shrink-0 border',
              {
                [completedCheckBorderColorClassName]:
                  !activeCheckedBorderClassName,
                [completedCheckBgColorClassName]: isComplete && !isActive,
                ...(activeCheckedBorderClassName && {
                  [activeCheckedBorderClassName]: isActive && !isComplete,
                }),
                ...(activeCheckedBgColorClassName && {
                  [activeCheckedBgColorClassName]: isActive && !isComplete,
                }),
              },
            )}
          >
            {!isActive && !isComplete && (
              <Typography size="medium-paragraph" isBold color="inherit">
                {index}
              </Typography>
            )}

            {!isActive && isComplete && (
              <motion.div
                className={clsx(
                  'flex rounded-full w-full h-full items-center justify-center',
                  completedCheckBgColorClassName,
                )}
                initial={{ scale: 0.0001, opacity: 0 }}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
                transition={checkmarkAnimationTransition}
                onAnimationComplete={onCheckAnimationComplete}
              >
                <Check className={`w-3 ${completedCheckColorClassName}`} />
              </motion.div>
            )}

            {isActive && (
              <Typography size="medium-paragraph" isBold color="inherit">
                {index}
              </Typography>
            )}
          </div>

          <Typography isBold size="medium-paragraph" element="p">
            <FormattedMessage {...title} />
          </Typography>
        </div>

        <div className="py-5 sm:pb-10 px-4 sm:px-10">
          {children}

          {!isComplete && footer}
        </div>
      </div>

      {isComplete && index !== 1 && (
        <button
          className="absolute top-0 left-0 w-full h-full bg-transparent cursor-pointer z-10 rounded-lg"
          onClick={onEditCurrentCard}
        />
      )}
    </div>
  );
};

const CheckableCard = forwardRef<HTMLDivElement, CheckableCardProps>(
  CheckableCardWithRef,
);

function Inner({
  consultationId,
  logger,
  routes,
  palette,
  trustpilotButton,
  brandColors,
  textStyles,
}: InitialPurchaseConfirmProps): React.ReactElement {
  const notify = useNotification();
  const environment = useEnvironment();
  const apollo = useApolloClient();
  const featureFlagClient = useFeatureFlagClient();
  const personalDetailsForm = useForm<PersonalDetailsFormFields>();
  const residentialAddressForm = useForm<ResidentialAddressFormFields>();
  const shippingAddressForm = useForm<ShippingAddressFormFields>();
  const paymentForm = useForm<PaymentFormFields>();
  const { formatMessage } = useIntl();
  const [pricingSessionId] = useState(v4());
  const [purchaseGroupId] = useState(v4());
  const [selections, setSelections] = useState<OfferingSelectionInput[]>();
  const history = useHistory();
  const { clearCode, persistedDiscountCode } = usePersistedDiscountFromURL();
  const [appliedCouponCode, setAppliedCouponCode] = useState<
    string | undefined
  >(persistedDiscountCode ?? undefined);
  const residentialAddressSectionRef = useRef<HTMLDivElement>(null);
  const shippingAddressSectionRef = useRef<HTMLDivElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const eventService = useEventServiceDirectContext();
  const [reviewTreatmentComplete, setReviewTreatmentComplete] = useState(false);
  const { isMobile } = useResponsive();
  const { questionsBannerBgColorClassName, questionsBannerTextColor } =
    brandColors;

  let startingStepIndex = 1;
  const [currentStepIndex, setCurrentStepIndex] = useState(
    startingStepIndex + 1,
  );
  useBackButtonBehaviour(() => history.push(routes.profile));

  useTitle(
    formatMessage({
      defaultMessage: 'Complete your payment',
      description: 'Page title for the Initial purchase confirm page',
    }),
  );

  const config = getConfig();

  const {
    initialPurchaseAddresses,
    supportShippingAddressValidation,
    showTermsAndConditionsCheckbox,
    initialPurchaseConfirmPersonalDetails,
  } = config.purchasePrompts;

  const experimentCPCopyUpdatesEnabled =
    featureFlagClient.getMultivariate('XP_CP_PAGE_COPY_UPDATES') ===
    'variation';
  const experimentInclusionTagsUpdatesEnabled = featureFlagClient.getBoolean(
    'XP_INCLUSION_TAGS_UPDATES',
  );

  const collectShippingAddress = initialPurchaseAddresses.has('shipping');
  const collectResidentialAddress = initialPurchaseAddresses.has('residential');
  const [isApplePayButtonRendered, setIsApplePayButtonRendered] =
    useState(false);
  const [showSimplifiedShippingAddress, setShowSimplifiedShippingAddress] =
    useState(true);
  const [
    showSimplifiedResidentialAddress,
    setShowSimplifiedResidentialAddress,
  ] = useState(true);
  const [fullShippingAddress, setFullShippingAddress] = useState('');
  const [fullResidentialAddress, setFullResidentialAddress] = useState('');
  const [shippingAddressOutOfRange, setShippingAddressOutOfRange] =
    useState(false);

  const stripe = useStripe();
  const stripeElements = useElements();

  const emailLabel = formatMessage({ defaultMessage: 'Email' });
  const emailValidation = useRequiredValidation(emailLabel);

  const confirmPasswordLabel = formatMessage({
    defaultMessage: 'Confirm Password',
    description: 'Pay for consultation page confirm password field label',
  });
  const confirmPasswordValidation = {
    ...useRequiredValidation(confirmPasswordLabel),
    ...matchesValue(personalDetailsForm.watch('password') || '', {
      name: 'confirmPassword',
      message: formatMessage({
        defaultMessage: 'Passwords do not match',
        description:
          'Pay for consultation page confirm password does not match password error message',
      }),
    }),
  };

  const passwordLabel = formatMessage({
    defaultMessage: 'Password',
    description: 'Pay for consultation page password field label',
  });
  const passwordValidation = {
    ...useRequiredValidation(passwordLabel),
    ...useMinLengthValidation(passwordLabel, 8),
  };

  const firstNameLabel = formatMessage({
    defaultMessage: 'First name',
    description: 'Pay for consultation page first name field label',
  });
  const firstNameValidation = useRequiredValidation(firstNameLabel);

  const lastNameLabel = formatMessage({ defaultMessage: 'Last name' });
  const lastNameValidation = useRequiredValidation(lastNameLabel);

  const preselectedOfferingSelection = usePreselectedOfferingSelection(logger);

  const ffAddressValidationExemption = useFeatureFlagBoolean(
    'FF_ADDRESS_VALIDATION_EXEMPTION',
  );

  const query = useQuery<
    InitialPurchaseConfirmQuery,
    InitialPurchaseConfirmQueryVariables
  >(
    gql`
      query InitialPurchaseConfirm(
        $consultationId: String!
        $pricingSessionId: ID!
        $offeringSelections: [OfferingSelectionInput!]!
        $noSelections: Boolean!
        $couponCodes: [String!]
        $proposedOfferingSelectionInput: InitialPurchaseProposedOfferingSelectionInput
      ) {
        initialPurchasePrice(
          consultationId: $consultationId
          pricingSessionId: $pricingSessionId
          offeringSelections: $offeringSelections
          couponCodes: $couponCodes
        ) @skip(if: $noSelections) {
          id
          amount
          originalAmount
          coupons {
            id
            code
            outcome
          }
        }
        validPhoneRegions {
          id
          countryCode
        }
        profile {
          id
          email
          phone
          isPasswordSet
          birthday
          lastName
          firstName
          shortAddressableName
          preferredUnitSystem
          address {
            id
            city
            line1
            line2
            state
            country
            postalCode
            prefecture
            municipality
            deliveryInstructions
            townArea
          }
          residentialAddress {
            id
            city
            line1
            line2
            state
            country
            postalCode
            prefecture
            municipality
            townArea
          }
          medicalProfile {
            id
            weight
          }
          ...PaymentMethods
        }
        initialPurchaseConfirmPage(consultationId: $consultationId) {
          id
          faqs {
            id
            question
            answer
          }
        }
        consultation(id: $consultationId) {
          id
          type
          purchasePrompt {
            id
            ... on InitialPurchasePrompt {
              proposedOfferingSelection(
                input: $proposedOfferingSelectionInput
              ) {
                id
                offering {
                  id
                  friendlyName
                  advertisedShippingCadence
                  photoUrl
                  tags
                  contents {
                    id
                    rank
                    description
                  }
                }
                sequenceSelections {
                  id
                  sequence {
                    id
                    ... on PrescribableSequence {
                      addressValidationExempt
                    }
                  }
                  sequenceSet {
                    id
                  }
                }
              }
            }
          }
        }
      }
      ${PaymentMethods.fragment}
    `,
    {
      errorPolicy: 'all',
      variables: {
        consultationId,
        pricingSessionId,
        noSelections: !selections,
        offeringSelections: selections ?? [],
        couponCodes: appliedCouponCode ? [appliedCouponCode] : undefined,
        proposedOfferingSelectionInput: preselectedOfferingSelection
          ? {
              selection: preselectedOfferingSelection,
            }
          : undefined,
      },
      onCompleted(data) {
        if (
          data.initialPurchasePrice?.coupons &&
          data.initialPurchasePrice.coupons.length > 1
        ) {
          logger.error('expected none or one coupon');
        }

        if (!personalDetailsForm.formState.isDirty) {
          const personalDetailsFields: Partial<PersonalDetailsFormFields> = {
            email: data.profile?.email,
            phone: data.profile?.phone ?? undefined,
            birthday: data.profile?.birthday
              ? formatISODateToLocale(data.profile.birthday)
              : undefined,
            lastName: data.profile?.lastName ?? undefined,
            firstName: data.profile?.firstName ?? undefined,
          };
          personalDetailsForm.reset(personalDetailsFields);
        }

        if (
          !residentialAddressForm.formState.isDirty &&
          collectResidentialAddress
        ) {
          const addressDetailsFields: Partial<ResidentialAddressFormFields> =
            {};

          addressDetailsFields.residentialAddress = {
            city: data.profile?.residentialAddress?.city,
            line1: data.profile?.residentialAddress?.line1,
            line2: data.profile?.residentialAddress?.line2 ?? undefined,
            state: data.profile?.residentialAddress?.state ?? undefined,
            country:
              data.profile?.residentialAddress?.country ?? config.country,
            postalCode: data.profile?.residentialAddress?.postalCode,
            prefecture:
              data.profile?.residentialAddress?.prefecture ?? undefined,
            municipality:
              data.profile?.residentialAddress?.municipality ?? undefined,
            townArea: data.profile?.residentialAddress?.townArea ?? undefined,
          };

          residentialAddressForm.reset(addressDetailsFields);
          setFullResidentialAddress(
            getFullAddressString(addressDetailsFields.residentialAddress),
          );
        }

        if (!shippingAddressForm.formState.isDirty && collectShippingAddress) {
          const addressDetailsFields: Partial<ShippingAddressFormFields> = {};

          addressDetailsFields.shippingAddress = {
            city: data.profile?.address?.city ?? undefined,
            line1: data.profile?.address?.line1 ?? undefined,
            line2: data.profile?.address?.line2 ?? undefined,
            state: data.profile?.address?.state ?? undefined,
            country: data.profile?.address?.country ?? config.country,
            postalCode: data.profile?.address?.postalCode ?? undefined,
            prefecture: data.profile?.address?.prefecture ?? undefined,
            municipality: data.profile?.address?.municipality ?? undefined,
            townArea: data.profile?.address?.townArea ?? undefined,
          };

          shippingAddressForm.reset(addressDetailsFields);
          setFullShippingAddress(
            getFullAddressString(addressDetailsFields.shippingAddress),
          );
        }

        setTimeout(() => {
          setReviewTreatmentComplete(true);
        }, 1500);
      },
    },
  );

  const data = query.data ?? query.previousData;
  const consultation = data?.consultation;
  const profile = data?.profile;
  const purchasePrompt = consultation?.purchasePrompt;
  const ipp = data?.initialPurchasePrice;
  const initialPurchaseConfirmFaqs = data?.initialPurchaseConfirmPage?.faqs;
  const consultationConfig = useConsultationFlowConfig(consultation?.type);

  const coupon = ipp?.coupons?.[0];

  useEffect(() => {
    setSelections((s) => {
      if (s) {
        // We don't expect this to change after the page has already loaded.
        return s;
      }

      if (purchasePrompt?.__typename !== 'InitialPurchasePrompt') {
        return s;
      }

      const selection = purchasePrompt.proposedOfferingSelection;
      if (!selection?.offering) {
        return s;
      }

      if (!selection.sequenceSelections) {
        return s;
      }

      const sequenceSelections: SequenceSelectionInput[] = [];
      for (const ss of selection.sequenceSelections) {
        if (ss.sequence?.id && ss.sequenceSet?.id) {
          sequenceSelections.push({
            sequenceId: ss.sequence.id,
            sequenceSetId: ss.sequenceSet.id,
          });
        }
      }

      return [{ offeringId: selection.offering.id, sequenceSelections }];
    });
  }, [purchasePrompt]);

  if (query.loading && data?.initialPurchasePrice == null) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!purchasePrompt) {
    logger.error(
      `no purchase prompt for consultation id "${consultationId}", redirecting to profile`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (purchasePrompt.__typename !== 'InitialPurchasePrompt') {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" type was expected to be InitialPurchasePrompt but is "${purchasePrompt.__typename}"`,
    );
    return <Redirect to={routes.profile} />;
  }

  const offeringSelection = purchasePrompt.proposedOfferingSelection;
  if (!offeringSelection) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering selection`,
    );
    return <Redirect to={routes.profile} />;
  }

  const exemptFromShippingValidation =
    offeringSelection?.sequenceSelections?.every((ss) => {
      if (ffAddressValidationExemption === true) {
        return true;
      }

      if (ss.sequence?.__typename !== 'PrescribableSequence') {
        return true;
      }

      if (ss.sequence.addressValidationExempt) {
        return true;
      }

      return false;
    });

  const offering = offeringSelection?.offering;
  if (!offering) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (!stripe) {
    logger.error(`stripe is not set`);
    return <Redirect to={routes.profile} />;
  }

  if (!stripeElements) {
    logger.error(`stripeElements is not set`);
    return <Redirect to={routes.profile} />;
  }

  if (!uiStorages.local.isSupported()) {
    logger.error('local storage is not available');
    return <Redirect to={routes.profile} />;
  }

  let originalAmount: string | undefined;
  let localisedAmount = '—';
  if (typeof ipp?.amount === 'number') {
    localisedAmount = formatCurrency(
      mapBrandToAdaptersBrand(config.brand),
      ipp.amount,
      {
        includeSymbol: true,
        includeDecimals: true,
        omitZeroDecimals: true,
        signDisplay: 'never',
      },
    );

    if (ipp.originalAmount > ipp.amount) {
      originalAmount = formatCurrency(
        mapBrandToAdaptersBrand(config.brand),
        ipp.originalAmount,
        {
          includeSymbol: true,
          includeDecimals: true,
          omitZeroDecimals: true,
          signDisplay: 'never',
        },
      );
    }
  }

  const formCouponCode = paymentForm.watch('couponCode');

  const displayPaymentMethodSeparator = (
    event: StripeExpressCheckoutElementReadyEvent,
  ) =>
    setIsApplePayButtonRendered(
      event.availablePaymentMethods?.applePay ?? false,
    );

  const verifyTermsHaveBeenAccepted = async (
    event: StripeExpressCheckoutElementClickEvent,
  ) => {
    if (showTermsAndConditionsCheckbox) {
      const agreedToTerms = await paymentForm.control.trigger('agreedToTerms');

      if (agreedToTerms) {
        event.resolve();
      }
    } else {
      event.resolve();
    }
  };

  const scrollSectionIntoView = (ref: React.RefObject<HTMLElement>) =>
    scrollToRef(ref, scrollOffset);

  const onSubmitPersonalDetails: SubmitHandler<
    PersonalDetailsFormFields
  > = async (fields) => {
    if (fields.birthday) {
      fields.birthday = formatLocaleDateToISO(fields.birthday);
    }

    const errorMsg = formatMessage({
      defaultMessage: 'Failed to confirm your personal details',
      description:
        'Confirm personal details for consultation page error message copy',
    });

    try {
      await apollo.mutate<
        InitialPurchaseConfirmUpdateProfileMutation,
        InitialPurchaseConfirmUpdateProfileMutationVariables
      >({
        mutation: updateProfileMutation,
        variables: {
          birthday: fields.birthday,
          firstName: fields.firstName,
          password: fields.password,
          phone: fields.phone,
          lastName: fields.lastName,
        },
        context: {
          skipErrorNotification: true,
        },
        errorPolicy: 'all',
      });

      setCurrentStepIndex((_) => ++_);
    } catch {
      notify.error({ message: errorMsg });
      return;
    }
  };

  const onSubmitResidentialAddress: SubmitHandler<
    ResidentialAddressFormFields
  > = async (fields) => {
    const errMsg = formatMessage({
      defaultMessage: 'Failed to confirm your address',
      description: 'Pay for consultation page error message copy',
    });

    if (fields.residentialAddress) {
      fields.residentialAddress.country = config.country;
    }

    try {
      await apollo.mutate<
        InitialPurchaseConfirmUpdateResidentialAddressMutation,
        InitialPurchaseConfirmUpdateResidentialAddressMutationVariables
      >({
        mutation: updateResidentialAddressMutation,
        variables: {
          residentialAddress: fields.residentialAddress ?? {},
        },
        context: {
          skipErrorNotification: true,
        },
        errorPolicy: 'all',
      });

      setCurrentStepIndex((_) => ++_);
    } catch {
      notify.error({ message: errMsg });
      return;
    }
  };

  const onSubmitShippingAddress: SubmitHandler<
    ShippingAddressFormFields
  > = async (fields) => {
    const errMsg = formatMessage({
      defaultMessage: 'Failed to confirm your address',
      description: 'Pay for consultation page error message copy',
    });

    if (supportShippingAddressValidation && !exemptFromShippingValidation) {
      try {
        const line1 = fields.shippingAddress?.line1;
        const line2 = fields.shippingAddress?.line2;
        const suburb = fields.shippingAddress?.city;
        const postcode = fields.shippingAddress?.postalCode;

        if (!(line1 && postcode && suburb)) {
          return;
        }

        const addressWithinRangeQuery = await apollo.query<
          InitialPurchaseConfirmShippingAddressWithinRangeQuery,
          InitialPurchaseConfirmShippingAddressWithinRangeQueryVariables
        >({
          query: gql`
            query InitialPurchaseConfirmShippingAddressWithinRange(
              $line1: String!
              $line2: String
              $suburb: String!
              $postcode: String!
            ) {
              shippingAddressWithinRange(
                line1: $line1
                line2: $line2
                postcode: $postcode
                suburb: $suburb
              )
            }
          `,
          variables: {
            line1,
            line2,
            suburb,
            postcode,
          },
          context: {
            skipErrorNotification: true,
          },
          errorPolicy: 'all',
        });

        if (!addressWithinRangeQuery.data.shippingAddressWithinRange) {
          setShippingAddressOutOfRange(true);
          return;
        }

        if (shippingAddressOutOfRange) {
          setShippingAddressOutOfRange(false);
        }
      } catch {
        notify.error({ message: errMsg });
        return;
      }
    }

    if (fields.shippingAddress) {
      fields.shippingAddress.country = config.country;
    }

    try {
      await apollo.mutate<
        InitialPurchaseConfirmUpdateShippingMutation,
        InitialPurchaseConfirmUpdateShippingMutationVariables
      >({
        mutation: updateShippingMutation,
        variables: {
          shippingAddress: fields.shippingAddress ?? {},
        },
        context: {
          skipErrorNotification: true,
        },
        errorPolicy: 'all',
      });

      setCurrentStepIndex((_) => ++_);
    } catch {
      notify.error({ message: errMsg });
      return;
    }
  };

  const setupPurchaseOfferingAndGetReturnUrl = (
    paymentMethod: PurchaseOfferingsIntentHandlerInput['gateway'],
    offerings: OfferingSelectionInput[],
    amount: number,
  ): URL => {
    const purchaseOfferingsInput: PurchaseOfferingsIntentHandlerInput = {
      gateway: paymentMethod,
      offerings,
      pricingSessionId,
      consultationId,
      expectedChargeAmount: amount,
      purchaseGroupId,
      source: 'CP',
      consent: false,
      onCompleteRoute: consultationConfig?.requiresScreeningQuiz
        ? routes.confirmation
        : routes.orderConfirmed,
    };

    if (coupon?.outcome === 'SUCCESS') {
      purchaseOfferingsInput.couponCode = appliedCouponCode;
    } else {
      purchaseOfferingsInput.couponCode = null;
    }

    // The user may not yet have given us a shipping address at this point
    // but we require a shipping address to purchase offerings, even if we
    // collect this at later stages.
    const { residentialAddress } = residentialAddressForm.getValues();
    const { shippingAddress } = shippingAddressForm.getValues();

    if (!profile?.address) {
      purchaseOfferingsInput.shippingAddress = residentialAddress;
    }

    if (shippingAddress) {
      purchaseOfferingsInput.shippingAddress = shippingAddress;
    }

    const localStorageKeyForPurchaseOfferingsInput = 'purchaseOfferingsInput';
    uiStorages.local.setValue(
      localStorageKeyForPurchaseOfferingsInput,
      unicodeToBase64(JSON.stringify(purchaseOfferingsInput)),
    );

    const url = new URL(
      routes.handlePurchaseOfferingsIntent,
      window.location.origin,
    );

    url.searchParams.set(
      'purchaseOfferingsInputStorageKey',
      encodeURIComponent(localStorageKeyForPurchaseOfferingsInput),
    );

    return url;
  };

  const handleStripeCheckoutErrorIfNeeded = (error: StripeError) => {
    const errMsg = formatMessage({
      defaultMessage: 'Failed to confirm your payment',
      description: 'Pay for consultation page error message copy',
    });

    if (error) {
      logger.error(
        'confirmStripe handler failed during initial purchase confirm',
        {
          error,
        },
      );
      notify.error({
        message: error.message ?? errMsg,
      });
    }
  };

  const onSubmitPaymentDetails: SubmitHandler<PaymentFormFields> = async (
    fields,
  ) => {
    if (selections === undefined) {
      return logger.error('selections is undefined');
    }

    if (typeof ipp?.amount !== 'number') {
      return logger.error('ipp is not a number');
    }

    const returnUrl = setupPurchaseOfferingAndGetReturnUrl(
      fields.payment.method,
      selections,
      ipp.amount,
    );

    for (const c of ipp.coupons ?? []) {
      if (c.code === persistedDiscountCode) {
        clearDiscountParams();
        clearCode();
      }
    }

    const errMsg = formatMessage({
      defaultMessage: 'Failed to confirm your payment',
      description: 'Pay for consultation page error message copy',
    });

    if (
      fields.payment.method === 'STRIPE' &&
      fields.payment.paymentDetailsCompleted
    ) {
      const { error } = await stripe.confirmSetup({
        elements: stripeElements,
        confirmParams: {
          return_url: returnUrl.toString(),
        },
      });

      handleStripeCheckoutErrorIfNeeded(error);
    } else if (fields.payment.method === 'ZIP' && !data?.profile?.zip?.valid) {
      try {
        const resp = await apollo.mutate<
          InitialPurchaseConfirmInitiateZipCheckoutMutation,
          InitialPurchaseConfirmInitiateZipCheckoutMutationVariables
        >({
          mutation: initiateZipCheckoutMutation,
          variables: {
            input: {
              offeringSelections: selections,
              pricingSessionId: pricingSessionId,
              couponCodes: appliedCouponCode ? [appliedCouponCode] : undefined,
              redirectUrl: returnUrl.toString(),
            },
          },
          errorPolicy: 'all',
          context: {
            skipErrorNotification: true,
          },
        });

        if (!resp?.data?.initiateFlexiZipCheckout?.redirectUrl) {
          throw new Error('Unable to initiate zip checkout');
        }

        window.location.assign(resp.data.initiateFlexiZipCheckout.redirectUrl);
      } catch {
        notify.error({ message: errMsg });
        return;
      }
    } else if (
      (fields.payment.method === 'STRIPE' &&
        !fields.payment.paymentDetailsCompleted) ||
      (fields.payment.method === 'ZIP' && data?.profile?.zip?.valid)
    ) {
      // We're using prefilled details here so we just redirect without hitting Stripe/Zip
      history.push({
        pathname: returnUrl.pathname,
        search: returnUrl.search,
      });
      return;
    }
  };

  const onExpressCheckoutConfirm = async () => {
    eventService?.uiInteractionTracked({
      elementName: 'initialPurchaseConfirmExpressCheckoutPayment',
      elementType: UIInteractionElementType.BUTTON,
      interaction: UIInteractionInteraction.CLICKED,
      pageUrl: window.location.toString(),
    });

    if (selections === undefined) {
      return logger.error('selections is undefined');
    }

    if (typeof ipp?.amount !== 'number') {
      return logger.error('ipp is not a number');
    }

    for (const c of ipp.coupons ?? []) {
      if (c.code === persistedDiscountCode) {
        clearDiscountParams();
        clearCode();
      }
    }

    const returnUrl = setupPurchaseOfferingAndGetReturnUrl(
      'STRIPE',
      selections,
      ipp.amount,
    );

    const { error } = await stripe.confirmSetup({
      elements: stripeElements,
      confirmParams: {
        return_url: returnUrl.toString(),
      },
    });

    handleStripeCheckoutErrorIfNeeded(error);
  };

  const purchaseConfirmSupportContactLink = getZendeskRequestUrl({
    params: config.weightInitialPurchaseContactParams,
  });
  const supportBannerLink = config.engagementSupportUrl;
  const blurbMessages = experimentInclusionTagsUpdatesEnabled
    ? [
        appBlurbMessages.progressTrack.title,
        appBlurbMessages.community.title,
        appBlurbMessages.results.title,
      ]
    : [
        appBlurbMessages.support.title,
        appBlurbMessages.tools.title,
        appBlurbMessages.content.title,
      ];

  return (
    <div>
      {experimentInclusionTagsUpdatesEnabled &&
        questionsBannerBgColorClassName &&
        questionsBannerTextColor &&
        supportBannerLink && (
          <div className={`py-3 ${questionsBannerBgColorClassName}`}>
            <Typography
              size="xs"
              element="p"
              textAlign="center"
              color={questionsBannerTextColor}
            >
              <FormattedMessage
                defaultMessage="<link><b>Have questions?</b> Our team is here to help - click to <b>schedule a call.</b></link>"
                description="Banner"
                values={{
                  b: (chunks) => <span className="font-bold">{chunks}</span>,
                  link: (text) => (
                    <a
                      href={supportBannerLink}
                      className="text"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {text}
                    </a>
                  ),
                }}
              />
            </Typography>
          </div>
        )}
      <div className="max-w-screen-sm px-4 py-12 gap-10 mx-auto flex flex-col">
        <div className="flex flex-col gap-4">
          <div className={textStyles?.titleMargin ?? 'mx-auto'}>
            <Typography
              isBold
              size={textStyles?.titleSize ?? 'lg'}
              element="h1"
              textAlign="center"
            >
              {experimentCPCopyUpdatesEnabled ? (
                <FormattedMessage
                  defaultMessage="Almost there!{br}Complete your order and claim{br}your $150 discount"
                  description="Pay for consultation page title"
                  values={{
                    br: <br />,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="To continue to your consult, confirm your details and pay for your order"
                  description="Pay for consultation page title"
                />
              )}
            </Typography>
          </div>

          <Typography size="paragraph" element="p" textAlign="center">
            {experimentCPCopyUpdatesEnabled ? (
              <FormattedMessage
                defaultMessage="Fill in your details below and use code <b>WELCOME150 to save $50 per month for the next three months.</b>{br}{br}Get started today and your tailored treatment could be at your door in just 2 days."
                description="Pay for consultation page subtitle"
                values={{
                  b: (chunks) => (
                    <span className="font-semibold">{chunks}</span>
                  ),
                  br: <br />,
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="If you decide not to proceed or your {isGb, select, true {prescriber} other {practitioner}} decides you are not eligible for treatment, you'll be fully refunded. If you do proceed we offer a {isGb, select, true {25} other {30}}-day money back guarantee and free cancellation anytime."
                description="Pay for consultation page subtitle"
                values={{
                  isGb: config.countryCode === 'GB',
                }}
              />
            )}
          </Typography>

          {experimentCPCopyUpdatesEnabled && (
            <div className="pt-4">
              <Typography size="paragraph" element="p" textAlign="center">
                <FormattedMessage
                  defaultMessage="<i>Trusted by 100,000+ women worldwide</i>"
                  description="Pay for consultation page caption"
                  values={{
                    i: (chunks) => <span className="italic">{chunks}</span>,
                  }}
                />
              </Typography>
            </div>
          )}

          {trustpilotButton && (
            <div className="flex justify-center">{trustpilotButton}</div>
          )}
        </div>

        <CheckableCard
          title={
            experimentCPCopyUpdatesEnabled
              ? defineMessage({
                  defaultMessage: 'Your weight loss plan, tailored to you',
                  description:
                    'Pay for consultation page review treatment details card title',
                })
              : defineMessage({
                  defaultMessage: 'Review treatment',
                  description:
                    'Pay for consultation page review treatment details card title',
                })
          }
          index={startingStepIndex}
          isActive={startingStepIndex === currentStepIndex}
          isComplete={reviewTreatmentComplete}
          colors={brandColors}
        >
          <div className="flex gap-3 items-center mb-6">
            <img
              src={offering.photoUrl}
              className="h-16 rounded-lg sm:rounded-xl object-cover"
            />
            <Typography isBold size="medium-paragraph">
              {offering.friendlyName}
            </Typography>
          </div>
          <WeightLossGraphSVG
            firstName={
              data?.profile?.shortAddressableName ?? data?.profile?.firstName
            }
            initialWeight={data?.profile?.medicalProfile.weight}
            preferredUnitSystem={data?.profile?.preferredUnitSystem}
            showDisclaimer
          />

          <div className="mt-6">
            <div className="mb-3">
              <Typography isBold size="paragraph" element="p">
                <FormattedMessage
                  defaultMessage="What’s included in the {isGb, select, true {programme} other {program}}"
                  values={{
                    isGb: config.countryCode === 'GB',
                  }}
                />
              </Typography>
            </div>

            {[
              ...(offering.contents?.slice().sort((a, b) => a.rank - b.rank) ||
                []),
              ...(offering.tags.includes('show-app-blurb')
                ? blurbMessages
                : []),
            ].map((c) => {
              const text =
                'description' in c ? c.description : formatMessage(c);
              return (
                <div key={text} className="flex pt-2 pb-1">
                  <div className="w-6 h-6 flex items-center justify-center flex-shrink-0 -mt-0.5">
                    <Check
                      className={clsx('w-3', brandColors.checkColorClassName)}
                    />
                  </div>
                  <div className="ml-1">
                    <Typography
                      size="paragraph"
                      color={brandColors.bodyTextColor}
                    >
                      {text}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className={clsx(
              'mt-5 -mx-4 sm:-mx-10 -mb-5 sm:-mb-10 py-5 sm:px-10 px-5 flex items-center justify-between',
              brandColors.priceBgColorClassName,
            )}
          >
            <Typography
              isBold
              size={isMobile ? 'paragraph' : 'medium-paragraph'}
            >
              <FormattedMessage defaultMessage="First order from*" />
            </Typography>

            <Typography isBold size="large-paragraph">
              <span className="whitespace-nowrap">
                {originalAmount ? (
                  <span className="text-neutral-500 mr-4 line-through">
                    {originalAmount}
                  </span>
                ) : null}
                {localisedAmount}
              </span>
            </Typography>
          </div>
        </CheckableCard>

        <div>
          <Typography size="paragraph" color={brandColors.bodyTextColor}>
            {experimentCPCopyUpdatesEnabled ? (
              <FormattedMessage
                defaultMessage="*The cost of your plan may change based on your {isGb, select, true {prescriber} other {practitioner}}'s recommendation for treatment. If at any time you decide not to proceed with starting the program, or even up to 30 days into your program, we'll process a full refund of the first payment."
                values={{
                  isGb: config.countryCode === 'GB',
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="*Following your consult, this price may change based on the medication agreed upon with your {isGb, select, true {prescriber} other {practitioner}}. If this happens, we’ll organise the additional payment or refund."
                values={{
                  isGb: config.countryCode === 'GB',
                }}
              />
            )}
          </Typography>
        </div>

        {initialPurchaseConfirmPersonalDetails && (
          <CheckableCard
            title={defineMessage({
              defaultMessage: 'Personal details',
              description:
                'Pay for consultation page personal details form title',
            })}
            index={++startingStepIndex}
            isActive={startingStepIndex === currentStepIndex}
            isComplete={startingStepIndex < currentStepIndex}
            colors={brandColors}
            footer={
              <div className="mt-4">
                <Button
                  palette="alternate"
                  isFullWidth
                  eventElementName="initialPurchaseConfirmUpdateAccountDetails"
                  tabIndex={
                    startingStepIndex > currentStepIndex ? -1 : undefined
                  }
                  isLoading={personalDetailsForm.formState.isSubmitting}
                  onClick={personalDetailsForm.handleSubmit(
                    onSubmitPersonalDetails,
                  )}
                >
                  <FormattedMessage
                    defaultMessage="Continue"
                    description="Button to confirm details personal details for healthcare record"
                  />
                </Button>
              </div>
            }
            onEdit={setCurrentStepIndex}
            onCheckAnimationComplete={() => {
              if (collectResidentialAddress) {
                scrollSectionIntoView(residentialAddressSectionRef);
              } else if (collectShippingAddress) {
                scrollSectionIntoView(shippingAddressSectionRef);
              }
            }}
          >
            <form>
              <fieldset
                className="gap-4 flex flex-col"
                disabled={startingStepIndex !== currentStepIndex}
              >
                <Typography size="paragraph">
                  {config.countryCode === 'GB' ? (
                    <FormattedMessage
                      defaultMessage="To create your medical record, your {isGb, select, true {prescriber} other {practitioner}} will need to confirm your details and residential address is correct. Please ensure that the details you provide are truthful."
                      values={{
                        isGb: config.countryCode === 'GB',
                      }}
                    />
                  ) : (
                    <FormattedMessage defaultMessage="Please confirm your personal details for your order" />
                  )}
                </Typography>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <TextInput
                      ref={personalDetailsForm.register(firstNameValidation)}
                      label={firstNameLabel}
                      name="firstName"
                      errorMessage={
                        personalDetailsForm.errors?.firstName?.message
                      }
                    />
                  </div>
                  <div>
                    <TextInput
                      ref={personalDetailsForm.register(lastNameValidation)}
                      label={lastNameLabel}
                      name="lastName"
                      errorMessage={
                        personalDetailsForm.errors?.lastName?.message
                      }
                    />
                  </div>

                  <div className="col-span-1">
                    <IntlDOBInput
                      register={personalDetailsForm.register}
                      errorMessage={
                        personalDetailsForm.errors?.birthday?.message
                      }
                    />
                  </div>

                  <div className="col-span-1 md:col-span-2">
                    <IntlMobileNumberInput
                      countryCodes={
                        data?.validPhoneRegions?.map(
                          ({ countryCode }) => countryCode,
                        ) ?? []
                      }
                      register={personalDetailsForm.register}
                      errorMessage={personalDetailsForm.errors?.phone?.message}
                    />
                  </div>
                </div>

                {!data?.profile?.isPasswordSet && (
                  <div className="-mt-5 -mb-5">
                    <Divider variant="separator" />
                  </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="col-span-1 md:col-span-2">
                    <TextInput
                      ref={personalDetailsForm.register(emailValidation)}
                      label={emailLabel}
                      name="email"
                      disabled
                    />
                  </div>

                  {!data?.profile?.isPasswordSet && (
                    <>
                      <div className="col-span-1 md:col-span-2">
                        <TextInput
                          name="password"
                          ref={personalDetailsForm.register(passwordValidation)}
                          label={passwordLabel}
                          type="password"
                          errorMessage={
                            personalDetailsForm.errors?.password?.message
                          }
                        />
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <TextInput
                          name="confirmPassword"
                          ref={personalDetailsForm.register(
                            confirmPasswordValidation,
                          )}
                          label={confirmPasswordLabel}
                          type="password"
                          errorMessage={
                            personalDetailsForm.errors?.confirmPassword?.message
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </fieldset>
            </form>
          </CheckableCard>
        )}

        {collectResidentialAddress && (
          <CheckableCard
            ref={residentialAddressSectionRef}
            title={defineMessage({
              defaultMessage: 'Confirm residential address',
              description:
                'Pay for consultation page residential address form title',
            })}
            index={++startingStepIndex}
            isActive={startingStepIndex === currentStepIndex}
            isComplete={startingStepIndex < currentStepIndex}
            colors={brandColors}
            footer={
              <div className="mt-6">
                <Button
                  palette="alternate"
                  isFullWidth
                  eventElementName="initialPurchaseConfirmUpdateAddress"
                  tabIndex={
                    startingStepIndex > currentStepIndex ? -1 : undefined
                  }
                  isLoading={residentialAddressForm.formState.isSubmitting}
                  onClick={async (fields) => {
                    const isValid = await residentialAddressForm.trigger();
                    if (!isValid) {
                      setShowSimplifiedResidentialAddress(false);
                    }

                    residentialAddressForm.handleSubmit(
                      onSubmitResidentialAddress,
                    )(fields);
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Continue"
                    description="Button to confirm residential address details for healthcare record"
                  />
                </Button>
              </div>
            }
            onEdit={setCurrentStepIndex}
            onCheckAnimationComplete={() => {
              if (collectShippingAddress) {
                scrollSectionIntoView(shippingAddressSectionRef);
              } else {
                scrollSectionIntoView(paymentSectionRef);
              }
            }}
          >
            <form>
              <fieldset
                className="gap-1 flex flex-col"
                disabled={startingStepIndex !== currentStepIndex}
              >
                <div className="flex flex-col">
                  <IntlAddressInput
                    name="residentialAddress"
                    registerField={residentialAddressForm.register}
                    errors={residentialAddressForm.errors.residentialAddress}
                    useAutoComplete
                    showSimplifiedForm={showSimplifiedResidentialAddress}
                    onChange={(addressFields: AddressFieldsIntl) =>
                      setFullResidentialAddress(
                        getFullAddressString(addressFields),
                      )
                    }
                  />
                  {fullResidentialAddress &&
                    showSimplifiedResidentialAddress && (
                      <Typography size="small-text">
                        {fullResidentialAddress}
                      </Typography>
                    )}
                </div>
                <div className="text-right">
                  <button
                    type="button"
                    onClick={(): void =>
                      setShowSimplifiedResidentialAddress((_) => !_)
                    }
                  >
                    <Typography size="small-text" isBold decoration="underline">
                      {showSimplifiedResidentialAddress ? (
                        <FormattedMessage
                          description="Label for entering address manually"
                          defaultMessage="Enter your address manually"
                        />
                      ) : (
                        <FormattedMessage
                          description="Label for searching address"
                          defaultMessage="Hide address fields"
                        />
                      )}
                    </Typography>
                  </button>
                </div>
              </fieldset>
            </form>
          </CheckableCard>
        )}

        {collectShippingAddress && (
          <CheckableCard
            ref={shippingAddressSectionRef}
            title={defineMessage({
              defaultMessage: 'Confirm shipping address',
              description:
                'Pay for consultation page shipping address form title',
            })}
            index={++startingStepIndex}
            isActive={startingStepIndex === currentStepIndex}
            isComplete={startingStepIndex < currentStepIndex}
            colors={brandColors}
            footer={
              <div className="mt-5">
                <Button
                  palette="alternate"
                  isFullWidth
                  eventElementName="initialPurchaseConfirmUpdateAddress"
                  tabIndex={
                    startingStepIndex > currentStepIndex ? -1 : undefined
                  }
                  isLoading={shippingAddressForm.formState.isSubmitting}
                  onClick={async (fields) => {
                    const isValid = await shippingAddressForm.trigger();
                    if (!isValid) {
                      setShowSimplifiedShippingAddress(false);
                    }

                    shippingAddressForm.handleSubmit(onSubmitShippingAddress)(
                      fields,
                    );
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Continue"
                    description="Button to confirm shipping address details for healthcare record"
                  />
                </Button>
              </div>
            }
            onEdit={setCurrentStepIndex}
            onCheckAnimationComplete={() =>
              scrollSectionIntoView(paymentSectionRef)
            }
          >
            <form>
              <fieldset
                className="gap-1 flex flex-col"
                disabled={startingStepIndex !== currentStepIndex}
              >
                <div className="flex flex-col">
                  <IntlAddressInput
                    name="shippingAddress"
                    registerField={shippingAddressForm.register}
                    errors={shippingAddressForm.errors.shippingAddress}
                    useAutoComplete
                    showSimplifiedForm={showSimplifiedShippingAddress}
                    onChange={(addressFields: AddressFieldsIntl) => {
                      setFullShippingAddress(
                        getFullAddressString(addressFields),
                      );
                      if (shippingAddressForm.errors.shippingAddress) {
                        shippingAddressForm.trigger();
                      }
                    }}
                  />
                  {fullShippingAddress && showSimplifiedShippingAddress && (
                    <Typography size="small-text">
                      {fullShippingAddress}
                    </Typography>
                  )}
                </div>
                <div className="text-right">
                  <button
                    type="button"
                    onClick={(): void =>
                      setShowSimplifiedShippingAddress((_) => !_)
                    }
                  >
                    <Typography size="small-text" isBold decoration="underline">
                      {showSimplifiedShippingAddress ? (
                        <FormattedMessage
                          description="Label for entering address manually"
                          defaultMessage="Enter your address manually"
                        />
                      ) : (
                        <FormattedMessage
                          description="Label for searching address"
                          defaultMessage="Hide address fields"
                        />
                      )}
                    </Typography>
                  </button>
                </div>

                {shippingAddressOutOfRange && (
                  <div className="w-full p-3 rounded bg-status-warning-100 border border-status-warning-500 text-left mt-5">
                    <div className="flex gap-2 items-center">
                      <Warning className="text-black" />
                      <Typography
                        color={sharedColors.neutral.black}
                        isBold
                        size="paragraph"
                      >
                        <FormattedMessage
                          defaultMessage="Please use a different address"
                          description="error message for address validation"
                        />
                      </Typography>
                    </div>
                    <div className="pl-6 flex flex-col space-y-2 mt-1">
                      <Typography
                        color={sharedColors.neutral[700]}
                        size="paragraph"
                      >
                        <FormattedMessage
                          defaultMessage="Our delivery partner doesn’t ship to this address. Find an <a>alternative postcode we ship to here.</a> Please note, our delivery partner can't deliver to post offices, PO boxes, or parcel lockers."
                          values={{
                            a: (chunk) => (
                              <a
                                className="underline cursor"
                                href={config.alternativeShippingAddressPost}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {chunk}
                              </a>
                            ),
                          }}
                        />
                      </Typography>
                      <Typography
                        color={sharedColors.neutral[700]}
                        size="paragraph"
                      >
                        <FormattedMessage
                          defaultMessage="If you need support, please <a>send us a message.</a>"
                          values={{
                            a: (chunk) => (
                              <a
                                className="underline cursor"
                                href={config.zendeskRequestUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {chunk}
                              </a>
                            ),
                          }}
                        />
                      </Typography>
                    </div>
                  </div>
                )}
              </fieldset>
            </form>
          </CheckableCard>
        )}

        <CheckableCard
          ref={paymentSectionRef}
          title={
            experimentCPCopyUpdatesEnabled
              ? defineMessage({
                  defaultMessage:
                    'Confirm payment and start your weight loss treatment',
                  description:
                    'Pay for consultation page card details form title',
                })
              : defineMessage({
                  defaultMessage: 'Confirm payment',
                  description:
                    'Pay for consultation page card details form title',
                })
          }
          index={++startingStepIndex}
          isActive={startingStepIndex === currentStepIndex}
          isComplete={startingStepIndex < currentStepIndex}
          colors={brandColors}
          footer={
            <div className="mt-6">
              <Button
                palette={palette?.confirmButton}
                isFullWidth
                eventElementName="initialPurchaseConfirmPayment"
                tabIndex={startingStepIndex > currentStepIndex ? -1 : undefined}
                isDisabled={typeof ipp?.amount !== 'number'}
                isLoading={paymentForm.formState.isSubmitting}
                onClick={paymentForm.handleSubmit(onSubmitPaymentDetails)}
              >
                <FormattedMessage
                  defaultMessage="{hasAmount, select, true {Confirm {amount} payment} other {Confirm payment}}"
                  description="Pay for consultation page button copy"
                  values={{
                    amount: localisedAmount,
                    hasAmount: !!localisedAmount,
                  }}
                />
              </Button>
            </div>
          }
        >
          <form>
            <fieldset
              className="gap-5 flex flex-col"
              disabled={startingStepIndex !== currentStepIndex}
            >
              {showTermsAndConditionsCheckbox && (
                <Checkbox
                  isChecked={false}
                  ref={paymentForm.register({
                    ...mustBeTrue(
                      formatMessage({
                        defaultMessage:
                          'To continue, you must agree to the terms of service',
                        description:
                          'Pay for consultation page terms of service required error message',
                      }),
                    ),
                  })}
                  errorMessage={paymentForm.errors?.agreedToTerms?.message}
                  name="agreedToTerms"
                  label={
                    <Typography size="paragraph">
                      <FormattedMessage
                        defaultMessage="I have read and agree to the <a>Terms & Conditions</a>, and I have read and acknowledge the <b>Privacy Notice</b>"
                        description="Pay for consultation page terms of conditions"
                        values={{
                          a: (chunks) => (
                            <a
                              className="underline"
                              href={`${environment.landingPageUrl}${config.urlPaths.termsAndConditions}`}
                              target="_blank"
                              rel="noreferrer"
                              tabIndex={
                                startingStepIndex > currentStepIndex
                                  ? -1
                                  : undefined
                              }
                            >
                              {chunks}
                            </a>
                          ),
                          b: (chunks) => (
                            <a
                              className="underline"
                              href={`${environment.landingPageUrl}${config.urlPaths.privacyNotice}`}
                              target="_blank"
                              rel="noreferrer"
                              tabIndex={
                                startingStepIndex > currentStepIndex
                                  ? -1
                                  : undefined
                              }
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </Typography>
                  }
                />
              )}

              <div>
                <div className="flex gap-3 items-end">
                  <div className="flex-grow">
                    <TextInput
                      ref={paymentForm.register({
                        maxLength: {
                          value: 100,
                          message: formatMessage({
                            defaultMessage:
                              'Discount code must be less than 100 characters',
                            description:
                              'UK initial purchase page discount code validation message',
                          }),
                        },
                      })}
                      name="couponCode"
                      label={formatMessage({
                        defaultMessage: 'Discount code',
                        description:
                          'Pay for consultation page discount code field label',
                      })}
                    />
                  </div>
                  <div className="mb-1">
                    <Button
                      level="secondary"
                      isLoading={query.loading}
                      isDisabled={
                        !formCouponCode || formCouponCode === appliedCouponCode
                      }
                      onClick={async () => {
                        const isValid = await paymentForm.trigger('couponCode');
                        if (!isValid) {
                          return;
                        }
                        setAppliedCouponCode(formCouponCode);
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Apply"
                        description="Pay for consultation page apply coupon code button copy"
                      />
                    </Button>
                  </div>
                </div>
                {(() => {
                  if (query.loading) {
                    return null;
                  }

                  let couponMessage: string | undefined;
                  let showSuccess = false;
                  switch (coupon?.outcome) {
                    case undefined:
                      break;
                    case 'EXPIRED':
                      couponMessage = formatMessage(
                        {
                          defaultMessage: 'Discount code {coupon} has expired',
                        },
                        { coupon: appliedCouponCode },
                      );
                      break;
                    case 'GENERIC_FAILURE':
                      couponMessage = formatMessage(
                        {
                          defaultMessage:
                            'Discount code {coupon} could not be applied',
                        },
                        { coupon: appliedCouponCode },
                      );
                      break;
                    case 'NOT_FOUND':
                      couponMessage = formatMessage(
                        { defaultMessage: 'Discount code {coupon} not found' },
                        { coupon: appliedCouponCode },
                      );
                      break;
                    case 'SUCCESS':
                      showSuccess = true;
                      couponMessage = formatMessage(
                        { defaultMessage: 'Discount code {coupon} applied' },
                        { coupon: appliedCouponCode },
                      );
                      break;
                  }

                  if (!couponMessage) {
                    return null;
                  }

                  return (
                    <div
                      className={clsx('flex gap-1 items-center mt-2', {
                        'text-status-success-500': showSuccess,
                        'text-status-error-500': !showSuccess,
                      })}
                    >
                      {showSuccess && (
                        <CircleTickOutline className="fill-current w-4 h-4" />
                      )}
                      <Typography inheritColor size="small-text" isBold>
                        {couponMessage}
                      </Typography>
                    </div>
                  );
                })()}
              </div>

              <div className={clsx(!isApplePayButtonRendered && 'hidden')}>
                <ExpressCheckoutElement
                  options={{
                    buttonHeight: 48,
                    paymentMethods: {
                      amazonPay: 'never',
                      link: 'never',
                      googlePay: 'never',
                      paypal: 'never',
                    },
                    buttonType: {
                      applePay: 'check-out',
                    },
                  }}
                  onReady={displayPaymentMethodSeparator}
                  onClick={verifyTermsHaveBeenAccepted}
                  onConfirm={onExpressCheckoutConfirm}
                />

                {isApplePayButtonRendered && (
                  <div className="flex flex-row gap-6 items-center mt-6 mb-1">
                    <hr className="flex-grow border-t border-neutral-200" />
                    <div className="text-black opacity-50 uppercase">
                      <Typography isBold size="paragraph" color="inherit">
                        <FormattedMessage defaultMessage="Or" />
                      </Typography>
                    </div>
                    <hr className="flex-grow border-t border-neutral-200" />
                  </div>
                )}
              </div>

              {profile && (
                <div>
                  <PaymentMethods
                    control={paymentForm.control}
                    errors={paymentForm.errors}
                    fragment={profile}
                    name="payment"
                    borderColorClassName={brandColors.separatorColorClassName}
                  />
                </div>
              )}
            </fieldset>
          </form>
        </CheckableCard>

        <div className="space-y-6">
          {initialPurchaseConfirmFaqs &&
            initialPurchaseConfirmFaqs.length > 0 && (
              <>
                <Typography isBold size="md">
                  <FormattedMessage
                    defaultMessage="Frequently asked questions"
                    description="Initial purchase confirm page"
                  />
                </Typography>
                <AccordionList
                  palette={palette?.accordion}
                  items={initialPurchaseConfirmFaqs.map((faq) => ({
                    title: (
                      <Typography size="medium-paragraph" isBold>
                        {faq.question}
                      </Typography>
                    ),
                    content: (
                      <Markdown
                        src={faq.answer}
                        formatWhitespace
                        linkTarget="blank"
                      />
                    ),
                  }))}
                />
              </>
            )}
          {purchaseConfirmSupportContactLink && (
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="<b>Still have questions?</b> <link>Contact our team</link> and we’ll get in touch."
                description="Initial confirm purchase support text and contact details"
                values={{
                  b: (text) => (
                    <Typography size="medium-paragraph" isBold element="span">
                      {text}
                    </Typography>
                  ),
                  link: (text) => (
                    <a
                      href={purchaseConfirmSupportContactLink}
                      className="text-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {text}
                    </a>
                  ),
                }}
              />
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

type InitialPurchaseConfirmProps = {
  consultationId: string;
  routes: {
    profile: string;
    confirmation: string;
    handlePurchaseOfferingsIntent: string;
    orderConfirmed: string;
  };
  logger: Logger;
  palette?: {
    primaryCard?: CardPalette;
    programCard?: CardPalette;
    nestedCard?: CardPalette;
    stillHaveQuestionsCard?: CardPalette;
    confirmButton?: ButtonPalette;
    accordion?: AccordionPalette;
  };
  trustpilotButton?: React.ReactNode;
  brandColors: {
    checkColorClassName: string;
    completedCheckColorClassName: string;
    completedCheckBgColorClassName: string;
    completedCheckBorderColorClassName: string;
    activeCheckedBorderClassName?: string;
    activeCheckedBgColorClassName?: string;
    borderInactiveColorClassName?: string;
    borderActiveColorClassName?: string;
    separatorColorClassName: string;
    bodyTextColor: string;
    priceBgColorClassName: string;
    questionsBannerBgColorClassName?: string;
    questionsBannerTextColor?: string;
  };
  stripeBrandTheme?: {
    borderRadius: string;
    colorPrimary: string;
    colorBackground: string;
    colorText: string;
    border: string;
    focusBorder: string;
    invalidBoxShadow: string;
    theme: 'flat' | 'stripe' | 'night';
  };
  textStyles?: {
    titleSize: TypographySize;
    titleMargin: string;
  };
};

export function InitialPurchaseConfirm(
  props: InitialPurchaseConfirmProps,
): React.ReactElement {
  const featureFlagClient = useFeatureFlagClient();
  const experimentConsultPayLoadingTimeEnabled =
    featureFlagClient.getMultivariate('XP_CONSULT_PAY_LOADING_TIME') ===
    'variation';
  return (
    <StripeProvider
      api="paymentIntents"
      logger={props.logger}
      stripeBrandTheme={props.stripeBrandTheme}
    >
      {experimentConsultPayLoadingTimeEnabled ? (
        <ExperimentConsultPayLoadingTimeInner {...props} />
      ) : (
        <Inner {...props} />
      )}
    </StripeProvider>
  );
}

const updateProfileMutation = gql`
  mutation InitialPurchaseConfirmUpdateProfile(
    $firstName: String
    $lastName: String
    $phone: String
    $password: String
    $birthday: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password: $password
      birthday: $birthday
    ) {
      id
      firstName
      phone
      birthday
    }
  }
`;

const updateResidentialAddressMutation = gql`
  mutation InitialPurchaseConfirmUpdateResidentialAddress(
    $residentialAddress: ResidentialAddressCreateInput!
  ) {
    updateResidentialAddress(residentialAddress: $residentialAddress) {
      id
      line1
      line2
      state
      country
      company
      building
      postalCode
      city
    }
  }
`;

const updateShippingMutation = gql`
  mutation InitialPurchaseConfirmUpdateShipping(
    $shippingAddress: AddressCreateWithoutUserInput!
  ) {
    updateShipping(address: $shippingAddress) {
      id
      address {
        id
        line1
        line2
        state
        country
        company
        building
        postalCode
        city
      }
    }
  }
`;

const initiateZipCheckoutMutation = gql`
  mutation InitialPurchaseConfirmInitiateZipCheckout(
    $input: InitiateFlexiZipCheckoutInput!
  ) {
    initiateFlexiZipCheckout(input: $input) {
      redirectUrl
    }
  }
`;
