import React from 'react';

import { QuestionProps } from './types';
import { QuizQuestionWeightLossGraph } from './custom/weight-loss-graph/weight-loss-graph';

export function CustomQuestionInput(props: QuestionProps): React.ReactElement {
  switch (props.question.shortcode) {
    case 'weightLossGraph':
      return <QuizQuestionWeightLossGraph />;
    default:
      return <></>;
  }
}
