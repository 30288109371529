import React from 'react';
import { FormattedMessage } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { QuizQuestionWeightLossGraphQuery } from '@customer-frontend/graphql-types';

import { useQuizState } from '@customer-frontend/quiz';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';

import { WeightLossGraphSVG } from './weight-loss-graph-svg';

export function QuizQuestionWeightLossGraph(): React.ReactElement {
  const quizState = useQuizState();

  const { data, loading } = useQuery<QuizQuestionWeightLossGraphQuery>(
    gql`
      query QuizQuestionWeightLossGraph {
        profile {
          id
          firstName
          shortAddressableName
          preferredUnitSystem
        }
      }
    `,
  );

  const weightAnswer = quizState.quizApplication?.answers.find(
    (a) => a.question?.shortcode === 'weight',
  );
  const currentWeightAnswer = weightAnswer?.answer;
  const initialWeight = Math.round(Number(currentWeightAnswer));

  if (loading || !initialWeight) {
    return (
      <>
        <div className="flex flex-col items-center text-center pb-6">
          <Typography size="lg">
            <FormattedMessage
              defaultMessage="Calculating your weight loss journey..."
              description="Calculating weight loss journey"
            />
          </Typography>
        </div>
        <div className="flex justify-center align-middle">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col items-center text-center gap-6">
      <WeightLossGraphSVG
        firstName={
          data?.profile?.shortAddressableName ?? data?.profile?.firstName
        }
        initialWeight={initialWeight}
        preferredUnitSystem={data?.profile?.preferredUnitSystem}
        showDisclaimer
      />
    </div>
  );
}
