import {
  CustomFlagKey,
  CustomFeatureFlags,
  MultivariateFlagKey,
  useFeatureFlagClient,
  MultivariateFeatureFlags,
} from '@customer-frontend/feature-flags';

const quizQuestionShortcodeToExperimentFlag: Record<
  string,
  CustomFlagKey | MultivariateFlagKey
> = {};

export const useQuizExperimentCohort = (
  shortcode?: string | null,
): string | null => {
  const featureFlagClient = useFeatureFlagClient();
  let experimentCohort: string | null = null;

  if (!shortcode) {
    return experimentCohort;
  }

  const experimentFlag = quizQuestionShortcodeToExperimentFlag[shortcode];

  if (!experimentFlag) {
    return experimentCohort;
  }

  if (experimentFlag in CustomFeatureFlags) {
    const flag = CustomFeatureFlags[experimentFlag as CustomFlagKey];
    experimentCohort = featureFlagClient.getCustom(flag.flagKey, {
      defaultValue: flag.defaultValue,
    });
  } else if (experimentFlag in MultivariateFeatureFlags) {
    experimentCohort = featureFlagClient.getMultivariate(
      experimentFlag as MultivariateFlagKey,
    );
  }
  return experimentCohort;
};
