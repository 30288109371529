import React from 'react';
import { QuizQuestion } from '@customer-frontend/graphql-types';

type QuizExperimentProps = {
  question: QuizQuestion;
  isLoading: boolean;
  experimentCohort: string;
};

export function QuizExperiments(
  props: QuizExperimentProps,
): React.ReactElement {
  switch (props.question.shortcode) {
    default:
      return <></>;
  }
}
